import Head from 'next/head';
import { CanonicalMetadata } from '../../../components/seo';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
export interface MetaDataProps {
  layout?: any;
  page?: any;
  path?: string;
}

export function MetaData({ layout, page, path }: MetaDataProps) {
  return (
    <Head>
      {path && <CanonicalMetadata path={path} />}
      {page?.data?.title && <title>{page?.data?.title}</title>}
      {page?.data?.description && (
        <meta name="description" content={page?.data?.description} />
      )}

      {page?.data?.title && (
        <meta property="og:title" content={page?.data?.title} />
      )}
      {page?.data?.description && (
        <meta property="og:description" content={page?.data?.description} />
      )}
      {page?.data?.image && (
        <meta property="og:image" content={page?.data?.image?.url} />
      )}

      {page?.data?.title && (
        <meta name="twitter:title" content={page?.data?.title} />
      )}
      {page?.data?.description && (
        <meta name="twitter:description" content={page?.data?.description} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      {page?.data?.image && (
        <meta name="twitter:image" content={page?.data?.image?.url} />
      )}

      {page?.data?.noindex && <meta name="robots" content="noindex" />}
      {!page?.data?.title && <title>{layout?.data?.title}</title>}
      {!page?.data?.description && (
        <meta name="description" content={layout?.data?.description} />
      )}
      <meta
        name="facebook-domain-verification"
        content="gny5a0lgx3dri2ko2a5rhmpemr7i5k"
      />
      <meta
        name="google-site-verification"
        content="Z85aOgRvX21Cih9_i8qbYjn0Irubn3EnVFhCElvACb0"
      />
    </Head>
  );
}
