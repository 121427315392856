import { SliceComponentType } from '@prismicio/react';
import dynamic from 'next/dynamic';

const CallToAction = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/call-to-action').then(
    (module) => module.CallToAction
  )
);

const CallToActionWithInput = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/call-to-action-with-input').then(
    (module) => module.CallToActionWithInput
  )
);

const ChannelsBlock = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/channels-block').then(
    (module) => module.ChannelsBlock
  )
);

const ComplianceDisclaimer = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/compliance-disclaimer').then(
    (module) => module.ComplianceDisclaimer
  )
);

const FaqSection = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/faqs').then(
    (module) => module.FaqSection
  )
);

const FindStoresInStates = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/find-stores-in-states').then(
    (module) => module.FindStoresInStates
  )
);

const FullBgBlockStatistics = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/full-bg-block-statistics').then(
    (module) => module.FullBgBlockStatistics
  )
);

const FullBgBlocks = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/full-bg-blocks').then(
    (module) => module.FullBgBlocks
  )
);

const FullWidthBlock = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/full-width-block').then(
    (module) => module.FullWidthBlock
  )
);

const GridImages = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/grid-images').then(
    (module) => module.GridImages
  )
);

const HeaderTitle = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/header-title').then(
    (module) => module.HeaderTitle
  )
);

const Hero = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/hero').then(
    (module) => module.Hero
  )
);

const HeroBannerSlider = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/hero-banner-slider').then(
    (module) => module.HeroBannerSlider
  )
);

const HeroSecondary = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/hero-secondary').then(
    (module) => module.HeroSecondary
  )
);

const HorizontalStorytellingCarousel = dynamic(() =>
  import(
    '@ee-monorepo/prismic/feature-slices/horizontal-storytelling-carousel'
  ).then((module) => module.HorizontalStorytellingCarousel)
);

const JobOpenings = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/job-openings').then(
    (module) => module.JobOpenings
  )
);

const LeaseAndLoanCards = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/lease-and-loan-cards').then(
    (module) => module.LeaseAndLoanCards
  )
);
const LogosSlice = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/logos-slice').then(
    (module) => module.LogosSlice
  )
);

const PaymentCalculator = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/payment-calculator').then(
    (module) => module.PaymentCalculator
  )
);

const Profiles = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/profiles').then(
    (module) => module.Profiles
  )
);

const Reviews = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/reviews').then(
    (module) => module.Reviews
  )
);

const ShoppingCategories = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/shopping-categories').then(
    (module) => module.ShoppingCategories
  )
);

const SmoothCarouselCard = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/smooth-carousel-card').then(
    (module) => module.SmoothCarouselCard
  )
);

const SplitCtaBlock = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/split-cta-block').then(
    (module) => module.SplitCtaBlock
  )
);

const Story = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/story').then(
    (module) => module.Story
  )
);

const StoryWithoutImage = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/story-without-image').then(
    (module) => module.StoryWithoutImage
  )
);

const StorytellingTemplateSideBySideText = dynamic(() =>
  import(
    '@ee-monorepo/prismic/feature-slices/storytelling-template-side-by-side-text'
  ).then((module) => module.StorytellingTemplateSideBySideText)
);

const SummaryText = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/summary-text').then(
    (module) => module.SummaryText
  )
);

const SummaryTextCarousel = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/summary-text-carousel').then(
    (module) => module.SummaryTextCarousel
  )
);

const VerticalStorytellingCarousel = dynamic(() =>
  import(
    '@ee-monorepo/prismic/feature-slices/vertical-storytelling-carousel'
  ).then((module) => module.VerticalStorytellingCarousel)
);

const VideoListActionBanner = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/video-list-action-banner').then(
    (module) => module.VideoListActionBanner
  )
);

const VideoReviews = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/video-reviews').then(
    (module) => module.VideoReviews
  )
);

const StoreSearch = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/store-search').then(
    (module) => module.StoreSearch
  )
);

const Redirect301 = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/redirect-301').then(
    (module) => module.Redirect301
  )
);

const CenteredCta = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/centered-cta').then(
    (module) => module.CenteredCta
  )
);

const HeroForm = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/hero-form').then(
    (module) => module.HeroForm
  )
);

const RectangleChannelsBlock = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/rectangle-channels-block').then(
    (module) => module.RectangleChannelsBlock
  )
);

const YouTubeFullWidthVideo = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/you-tube-full-width-video').then(
    (module) => module.YouTubeFullWidthVideo
  )
);

const RichTextSlice = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/rich-text').then(
    (module) => module.RichTextSlice
  )
);

const BlogPostCarousel = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/blog-post-carousel').then(
    (module) => module.BlogPostCarousel
  )
);

const EmbeddedContent = dynamic(() =>
  import('@ee-monorepo/prismic/feature-slices/embedded-content').then(
    (module) => module.EmbeddedContent
  )
);

export {
  CallToAction,
  CallToActionWithInput,
  ChannelsBlock,
  ComplianceDisclaimer,
  FaqSection,
  FindStoresInStates,
  FullBgBlockStatistics,
  FullBgBlocks,
  FullWidthBlock,
  GridImages,
  HeaderTitle,
  Hero,
  HeroBannerSlider,
  HeroSecondary,
  HorizontalStorytellingCarousel,
  JobOpenings,
  LeaseAndLoanCards,
  LogosSlice,
  PaymentCalculator,
  Profiles,
  Reviews,
  ShoppingCategories,
  SmoothCarouselCard,
  SplitCtaBlock,
  Story,
  StoryWithoutImage,
  StorytellingTemplateSideBySideText,
  SummaryText,
  SummaryTextCarousel,
  VerticalStorytellingCarousel,
  VideoListActionBanner,
  VideoReviews,
  RectangleChannelsBlock,
  StoreSearch,
  Redirect301,
  CenteredCta,
  HeroForm,
  YouTubeFullWidthVideo,
  RichTextSlice,
  BlogPostCarousel,
  EmbeddedContent,
};

export const components = {
  call_to_action: CallToAction,
  call_to_action_with_input: CallToActionWithInput,
  channels_block: ChannelsBlock,
  compliance_disclaimer: ComplianceDisclaimer,
  faq_section: FaqSection,
  find_stores_in_states: FindStoresInStates,
  full_bg_block_statistics: FullBgBlockStatistics,
  full_bg_blocks: FullBgBlocks,
  full_width_block: FullWidthBlock,
  grid_images: GridImages,
  header_title: HeaderTitle,
  hero: Hero,
  hero_banner_slider: HeroBannerSlider,
  hero_secondary: HeroSecondary,
  horizontal_storytelling_carousel: HorizontalStorytellingCarousel,
  job_openings: JobOpenings,
  lease_and_loan_cards: LeaseAndLoanCards,
  logos_slice: LogosSlice,
  payment_calculator: PaymentCalculator,
  profiles: Profiles,
  reviews: Reviews,
  shopping_categories: ShoppingCategories,
  smooth_carousel_card: SmoothCarouselCard,
  split_cta_block: SplitCtaBlock,
  story: Story,
  story_without_image: StoryWithoutImage,
  storytelling_template_side_by_side_text: StorytellingTemplateSideBySideText,
  summary_text: SummaryText,
  summary_text_carousel: SummaryTextCarousel,
  vertical_storytelling_carousel: VerticalStorytellingCarousel,
  video_list_action_banner: VideoListActionBanner,
  video_reviews: VideoReviews,
  store_search: StoreSearch,
  redirect301: Redirect301,
  centered_cta: CenteredCta,
  hero_form: HeroForm,
  rectangle_channels_block: RectangleChannelsBlock,
  you_tube_full_width_video: YouTubeFullWidthVideo,
  rich_text: RichTextSlice,
  blog_post_carousel: BlogPostCarousel,
  embedded_content: EmbeddedContent,
} as { [key: string]: SliceComponentType };
