import { useRouter } from 'next/router';
import { createClient } from '../prismic-configuration';
import { GetStaticPropsContext } from 'next';
import { SliceZone } from '@prismicio/react';
import { Shell } from '../components/shell';
import Loader from '../components/common/ui/loader/loader';
import { MetaData } from '../components/seo/metadata';
import { components } from '@ee-monorepo/prismic/feature-slices/all-slices';

export async function getStaticProps({
  locale,
  previewData,
}: GetStaticPropsContext<any, any>) {
  const client = createClient({ previewData });
  const queryResult = await client.getByUID<any>('home', 'homepage'); // TODO: 3rd param, Options, accepts e.g "lang: 'fr-fr'" query by locale
  return {
    props: {
      page: queryResult,
      slices: queryResult?.data?.slices,
      messages: {
        page: { ...require(`../i18n/pages/home/${locale}.json`) },
        shared: { ...require(`../i18n/shared/${locale}.json`) },
      },
    },
    revalidate: 30, // TODO: adjust revalidation time this later
  };
}

export function Index({ slices, layout, page }) {
  const router = useRouter();
  if (router.isFallback) {
    return <Loader />; // TODO: add progress indicator, edge case to build page on runtime
  }
  if (!page.id) {
    router.push('/404');
    return null;
  }

  return (
    <>
      <MetaData page={page} layout={layout} path={router.asPath} />
      <Shell layout={layout} banner={page.data?.banner}>
        <div>
          <SliceZone slices={slices} components={components} />
        </div>
      </Shell>
    </>
  );
}

export default Index;
